import { Vehicle } from "../../types/helpers";
import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface InputState {
  query: string;
  selectedRect: number;
  selectedRows: Array<number>;
  vehicles: Array<Vehicle>;
  date?: string;
  editStartError?: boolean;
  filletRestInputCount: number;
}

const initialState: InputState = {
  query: "",
  selectedRect: -1,
  selectedRows: [],
  vehicles: [],
  editStartError: false,
  filletRestInputCount: 0,
};

export const simpleSlice = createSlice({
  name: "simple",
  initialState,
  reducers: {
    setQuery: (state, action: PayloadAction<string>) => {
      state.query = action.payload;
    },
    setDate: (state, action: PayloadAction<string>) => {
      state.date = action.payload;
    },
    setVehicles: (state, action: PayloadAction<Array<Vehicle>>) => {
      state.vehicles = action.payload;
    },
    setSelectedRect: (state, action: PayloadAction<number>) => {
      state.selectedRect = action.payload;
    },
    setFilletRestInputCount: (state) => {
      state.filletRestInputCount = state.filletRestInputCount + 1;
    },
    setSelectedRows: (state, action: PayloadAction<Array<number>>) => {
      state.selectedRows = action.payload;
    },
    setEditStartError: (state, action: PayloadAction<boolean>) => {
      state.editStartError = action.payload;
    },
  },
});

export const {
  setQuery,
  setDate,
  setVehicles,
  setSelectedRect,
  setSelectedRows,
  setEditStartError,
  setFilletRestInputCount,
} = simpleSlice.actions;

export default simpleSlice.reducer;
