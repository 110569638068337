import { green, grey, orange, purple, red, yellow } from "../theme/colors";
import { DrivingStatusModeKey, StatusType } from "../types/statusCard";

export const statuses = [
  "DRIVING",
  "ON_DUTY",
  "SLEEPER",
  "OFF_DUTY",
  "PERSONAL_USE",
  "YARD_MOVE",
  "CYCLE",
] as const;

export const driverStatusDefinitions: {
  [k in DrivingStatusModeKey]: StatusType;
} = {
  driving: { color: green, mode: "DRIVING" },
  onDuty: { color: yellow, mode: "ON_DUTY" },
  sleeper: { color: grey[300], mode: "SLEEPER" },
  offDuty: { color: grey[1000], mode: "OFF_DUTY" },
  personalUse: { color: purple, mode: "PERSONAL_USE" },
  yardMove: { color: orange, mode: "YARD_MOVE" },
  cycle: { color: red, mode: "CYCLE" },
};

export const logCheckErrors = {
  location_check: "Location must change from the previous.",
  location_match: "Location must remain the same as the previous.",
  odometer_check: "Odometer must be greater than the previous.",
  odometer_match: "Odometer must remain the same as the previous.",
  engine_hours: "Engine hours must be greater than the previous.",
  empty_check: "Empty location/odometer/engine hours fields.",
  consequent_logins: "Two consequent Login statuses.",
  intermediate_freq:
    "The Intermediate event must be at exactly +1 hour from the previous event.",
};

export const saveLogeCheckErrors = {
  ...logCheckErrors,
  location_match:
    "Location value match - “Teleport occured. Location must remain the same as the previous.”",
  odometer_match:
    "Odometer value match - “Teleport occured. Odometer must remain the same as the previous.”",
};
