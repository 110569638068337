import { grey } from "../../../theme/colors";
import { StatusMode } from "../../../types/statusCard";
import { LogChart, LogChartType } from "./Chart";
import {
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import React, { ReactNode } from "react";

export const extendedModes = [
  "INTERMEDIATE",
  "ENGINE_POWER_UP",
  "ENGINE_SHUT_DOWN",
  "LOGIN",
  "LOGOUT",
  "CERTIFICATION",
];

export type ExtendedStatusMode =
  | StatusMode
  | "INTERMEDIATE"
  | "ENGINE_POWER_UP"
  | "ENGINE_SHUT_DOWN"
  | "LOGIN"
  | "LOGOUT"
  | "CERTIFICATION";

export type Totals = {
  [T in StatusMode]: number;
};

const LogEvents: React.FC<
  LogChartType & {
    child?: ReactNode;
    hasData?: boolean;
    setIsMouseOnChart: Function;
  }
> = ({ child, hasData, setIsMouseOnChart, ...props }) => {
  return (
    <>
      <Accordion defaultExpanded style={{ marginTop: 0 }}>
        <AccordionSummary
          expandIcon={<GridExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ margin: 0, paddingLeft: 0 }}
        >
          <Typography variant="h3" color={grey[600]} fontWeight={500}>
            Log events
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid
            container
            sx={{ backgroundColor: "#F8F8F8" }}
            alignItems="center"
            pt="20px"
            pb="10px"
            height={hasData ? "370px" : ""}
          >
            <Grid
              item
              onMouseEnter={() => setIsMouseOnChart(true)}
              onMouseLeave={() => setIsMouseOnChart(false)}
            >
              <LogChart {...props} />
            </Grid>
            <Grid item>{child}</Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default LogEvents;
