import async from "./components/Async";
import AuthGuard from "./components/guards/AuthGuard";
import GuestGuard from "./components/guards/GuestGuard";
import AuthLayout from "./layout/Auth";
import Layout from "./layout/Layout";
import Login from "./pages/auth/Login";
import Page404 from "./pages/auth/Page404";
import React from "react";
import { Navigate, RouteObject } from "react-router-dom";

const Logs = async(() => import("./pages/Logs"));
const BulkLogs = async(() => import("./pages/Logs/Bulk"));
const Info = async(() => import("./pages/Logs/Info"));
const Errors = async(() => import("./pages/Errors"));
const Dots = async(() => import("./pages/Dots"));
const DotForm = async(() => import("./pages/Dots/form"));
const Security = async(() => import("./pages/security"));
const Monitoring = async(() => import("./pages/Monitoring"));
const Administrator = async(() => import("./pages/Administrator"));
const Users = async(() => import("./pages/Users/Users"));
const UsersForm = async(() => import("./pages/Users/UserForm"));

export type RouteNames =
  | "Logs"
  | "Monitoring"
  | "Bulk logs"
  | "Log details"
  | "Errors"
  | "DOT Inspections"
  | "DOT Form"
  | "Security"
  | "Administrator"
  | "Users"
  | "UsersForm";

type routeType = {
  name: RouteNames;
  path: string;
  element: React.ReactElement;
  children?: any;
};

const privateRoutes: routeType[] = [
  {
    path: "/",
    element: <Navigate to="/logs" />,
    name: "Logs",
  },
  {
    path: "/logs",
    element: <Logs />,
    name: "Logs",
  },
  {
    path: "/checker",
    element: <Monitoring />,
    name: "Monitoring",
  },
  {
    path: "/bulk",
    element: <BulkLogs />,
    name: "Bulk logs",
  },
  {
    path: "/logs/details",
    element: <Info />,
    name: "Log details",
  },
  {
    path: "/dots",
    element: <Dots />,
    name: "DOT Inspections",
  },
  {
    path: "/dots/form/:id",
    element: <DotForm />,
    name: "DOT Form",
  },
  {
    path: "/errors",
    element: <Errors />,
    name: "Errors",
  },
  {
    path: "/security",
    element: <Security />,
    name: "Security",
  },
  {
    path: "/admin",
    element: <Administrator />,
    name: "Administrator",
  },
  {
    path: "/users",
    element: <Users />,
    name: "Users",
  },
  {
    path: "/users/form/:id",
    element: <UsersForm />,
    name: "UsersForm",
  },
];

const routes: RouteObject[] = [
  {
    path: "login",
    element: (
      <GuestGuard>
        <AuthLayout children={<Login />} />
      </GuestGuard>
    ),
  },
  {
    path: "/",
    element: (
      <AuthGuard>
        <Layout children={undefined} />
      </AuthGuard>
    ),
    children: privateRoutes,
  },
  {
    path: "*",
    element: <Page404 />,
  },
];

export default routes;
